<template>
    <div class="ex_succ">
        <div class="top">
            <img src="https://khome2.tuzuu.com/vita/consul2.png" @load="w"/>
            <div class="con flex-column-center">
                <div class="flex" style="margin-bottom: 11px">
                    <img src="https://khome2.tuzuu.com/vita/icon_9.png" style="width: 18px">
                    <strong style="font-size: 17px;margin-left: 5px;letter-spacing: 1px">
                        您的信息已提交～
                    </strong>
                </div>
                <div class="flex-column-center" style="font-size: 14px;line-height: 22px;margin-top: 5px">
                    <p>我们将在收到您回寄的样本后</p>
                    <p>为您进行专业的基因检测</p>
                </div>
            </div>
        </div>
        <div class="bottom flex-column-center">
            <div style="margin-top: 70px">
                您可以联系专属营养顾问获取检测报告
            </div>
            <div style="margin-top: 50px;font-size: 16px;line-height: 27px">
                <p style="font-weight: 700">{{ data.name }}</p>
                <p>请长按二维码和我联系吧</p>
            </div>
            <van-image width="144" :src="data.qrcode" @load="createPoster" style="margin-top: 26px"/>
        </div>
        <img :src="url" class="poster">
    </div>
</template>

<script>
    import html2canvas from "html2canvas"

    export default {
        name: "",
        created() {
            let id = this.$route.query.adviser_id
            this.$request.get(
                "get_adviser_qrcode", {
                    adviser_id: id || 0
                }
            ).then(res => {
                if (res.code == 1) {
                    this.data = res.data
                } else {
                    this.$toast(res.message)
                }
            })
        },
        data(){
            return{
                data:{},
                url: '',
            }
        },
        methods: {
            w() {
                this.$nextTick(() => {
                    let bgHeight = window.innerHeight
                    let topHeight = document.querySelector(".top").offsetHeight
                    let resHeight = bgHeight - topHeight -20 + 70
                    document.querySelector(".bottom").style.height = resHeight + 'px'
                })
            },
            createPoster() {
                setTimeout(() => {
                    new html2canvas(document.querySelector(".ex_succ"), {
                        allowTaint: false,
                        useCORS: true,
                        backgroundColor: null,
                        // scale:2,
                        dpi: 300
                    }).then(canvas => {
                        this.url = canvas.toDataURL("image/png")
                    })
                }, 200)
            }

        }
    }
</script>

<style scoped lang="less">
    .ex_succ {
        width: 100%;
        min-height: 100vh;
        background-color: #F3F3F4;
        position: relative;
    }

    .top {
        position: relative;

        .con {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 42px;
            color: white;
        }
    }

    .bottom {
        width: calc(100% - 20px);
        margin: 0 auto;
        border-radius: 10px 10px 0px 0px;
        background: white;
        margin-top: -70px;
        position: relative;
        font-size:15px;
        text-align: center;
        color: #3F3F3F;
    }
    .poster {
        position: absolute;
        top: 0;
        opacity: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
    }
</style>